import cn from 'classnames';
import type { CSSProperties } from 'react';
import React from 'react';

const DoubleArrowIcon: React.FC<{ className: string; style: CSSProperties }> = ({ className, style }) => (
    <svg viewBox="0 0 16 16" className={cn(className, 'styles_arrow_icon__3AWR5')} style={style}>
        <g>
            <path d="M8 3.397L4.65 7 4 6.301 8 2l4 4.301-.65.699zM8 8.397L4.65 12 4 11.301 8 7l4 4.301-.65.699z" />
        </g>
    </svg>
);

export default DoubleArrowIcon;
