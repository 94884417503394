import cn from 'classnames';
import type { FC } from 'react';

import styles from './styles.module.scss';

interface ApprovalProbabilityProps {
    category?: string;
    isNewDesignCard?: boolean;
}

const CATEGORY_LABELS_MAP_CARD_V1: { [k: string]: string } = {
    high: 'Высокое',
    medium: 'Среднее',
    low: 'Низкое',
};

const CATEGORY_LABELS_MAP_CARD_V2: { [k: string]: string } = {
    high: 'Высокая',
    medium: 'Средняя',
    low: 'Низкая',
};

const DEFAULT_LABEL_CARD_V2 = 'Нет данных';
const DEFAULT_LABEL_CARD_V1 = '—';

export const ApprovalProbability: FC<ApprovalProbabilityProps> = ({ category = '', isNewDesignCard = true }) => {
    const label = isNewDesignCard ? CATEGORY_LABELS_MAP_CARD_V2[category] : CATEGORY_LABELS_MAP_CARD_V1[category];
    const defaultLabel = isNewDesignCard ? DEFAULT_LABEL_CARD_V2 : DEFAULT_LABEL_CARD_V1;

    return <div className={cn(styles.root, styles[category])}>{label || defaultLabel}</div>;
};
