import React from 'react';

const EmptyBoxIcon: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <circle cx="50" cy="50" r="50" fill="#DCE6F9" fillOpacity="0.6" />
        <path d="M49.8886 45.3799V79.7247L23.2227 68.207L23.3499 33.9863L49.8886 45.3799Z" fill="#418DF9" />
        <path d="M49.8887 45.3983V79.7431L76.4249 68.6232V34.0938L49.8887 45.3983Z" fill="#A2BDFF" />
        <path d="M49.8886 45.3788L76.5545 34.2099L50.098 22.4824L23.2227 33.9307L49.8886 45.3788Z" fill="#0E84E5" />
        <path
            d="M15.9405 40.0565L42.7763 53.0823C42.8484 53.1173 42.93 53.1276 43.0086 53.1117C43.0872 53.0958 43.1584 53.0546 43.2112 52.9943L49.8885 45.3779L23.2225 33.9297L15.8808 39.4345C15.8306 39.4721 15.7909 39.522 15.7656 39.5794C15.7402 39.6368 15.7301 39.6998 15.7361 39.7622C15.742 39.8247 15.764 39.8846 15.7998 39.9361C15.8356 39.9876 15.8841 40.0291 15.9405 40.0565Z"
            fill="#699CFF"
        />
        <path
            d="M76.5546 34.209L83.9605 40.9046C84.0057 40.9454 84.04 40.9969 84.0603 41.0543C84.0806 41.1118 84.0862 41.1734 84.0766 41.2335C84.0671 41.2937 84.0426 41.3505 84.0056 41.3988C83.9685 41.4472 83.9199 41.4855 83.8643 41.5103L57.3594 53.3394C57.2886 53.371 57.2097 53.3793 57.1339 53.3631C57.0581 53.347 56.9894 53.3071 56.9377 53.2494L49.8887 45.378L76.5546 34.209Z"
            fill="#699CFF"
        />
        <path
            d="M50.0977 22.4818L76.6284 34.2092L80.4439 33.3249C80.5189 33.3075 80.5865 33.2669 80.6371 33.2088C80.6877 33.1507 80.7187 33.0782 80.7257 33.0015C80.7327 32.9248 80.7153 32.8479 80.676 32.7816C80.6367 32.7154 80.5775 32.6633 80.5068 32.6326L56.281 22.1356C56.228 22.1127 56.1704 22.1026 56.1127 22.1062L50.0977 22.4818Z"
            fill="#699CFF"
        />
        <path
            d="M50.0979 22.4815L23.7159 33.9844L20.3809 33.4239C20.3036 33.4095 20.2331 33.3707 20.1797 33.3131C20.1263 33.2555 20.0928 33.1822 20.0842 33.1042C20.0756 33.0261 20.0924 32.9473 20.1321 32.8795C20.1717 32.8117 20.2321 32.7585 20.3044 32.7276L45.1289 22.0292C45.1838 22.0059 45.2435 21.9963 45.3029 22.0013L50.0979 22.4815Z"
            fill="#699CFF"
        />
        <path
            d="M58.8674 60.1645C58.8234 59.0802 58.4148 58.2164 57.9548 58.235C57.4948 58.2537 57.1576 59.1478 57.2016 60.2321C57.2456 61.3163 57.6541 62.1802 58.1141 62.1615C58.5741 62.1429 58.9114 61.2488 58.8674 60.1645Z"
            fill="white"
        />
        <path
            d="M71.3747 57.3365C71.3467 56.2517 70.9509 55.3819 70.4907 55.3938C70.0305 55.4057 69.6801 56.2947 69.7081 57.3795C69.7361 58.4643 70.1319 59.3341 70.5921 59.3222C71.0523 59.3104 71.4027 58.4213 71.3747 57.3365Z"
            fill="white"
        />
        <path
            d="M67.2298 63.9625C67.1734 63.994 67.108 64.0053 67.0443 63.9945C66.9806 63.9837 66.9226 63.9515 66.8797 63.9031C66.724 63.7285 66.1977 63.2485 65.838 63.3349C65.4784 63.4213 65.06 64.103 64.9136 64.4196C64.8821 64.4878 64.8248 64.5406 64.7543 64.5665C64.6839 64.5924 64.606 64.5892 64.5379 64.5577C64.4697 64.5262 64.4169 64.4689 64.391 64.3985C64.3651 64.328 64.3682 64.2501 64.3998 64.182C64.4575 64.0572 64.9843 62.9578 65.7057 62.7845C66.4581 62.6037 67.2191 63.4325 67.3033 63.5277C67.3314 63.5594 67.352 63.5969 67.3637 63.6376C67.3753 63.6783 67.3777 63.721 67.3706 63.7628C67.3636 63.8045 67.3472 63.8441 67.3228 63.8787C67.2984 63.9132 67.2666 63.9419 67.2297 63.9626L67.2298 63.9625Z"
            fill="white"
        />
    </svg>
);

export default EmptyBoxIcon;
