import cn from 'classnames';
import type { CSSProperties } from 'react';
import React from 'react';

const ArrowIcon: React.FC<{ className: string; style: CSSProperties }> = ({ className, style }) => (
    <svg viewBox="0 0 8.1 4.8" className={cn(className, 'styles_arrow_icon__3AWR5')} style={style}>
        <path d="M7.8.4L4.1 4.1.4.4" />
    </svg>
);

export default ArrowIcon;
